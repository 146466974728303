import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError, Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { HTTPSTATUS, HTTPSTATUSCODE } from "../../utils/enum/enum";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialog,
    private toastr: ToastrService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Handle successful responses
          this.handleResponseHeaders(event);
          this.handleResponseStatus(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleErrorHeaders(error);
        this.handleErrorStatus(error);
        return throwError(() => error.error.detail || error.error.message || error.error.error || 'An error occurred');
      })
    );
  }

  private handleResponseHeaders(response: HttpResponse<any>): void {
    if (response.type !== 4) return;

    const accessToken = response.headers.get('x-access-token');
    const refreshToken = response.headers.get('x-refresh-token');
    const oldToken = sessionStorage.getItem('authorization');
    if (accessToken && (!oldToken || oldToken !== accessToken)) {
      sessionStorage.setItem('authorization', accessToken);
    }

    if (refreshToken) {
      sessionStorage.setItem('refershtoken', refreshToken);
    }
  }

  private handleResponseStatus(response: HttpResponse<any>): void {
    const status = response.status;
    const message = response.body?.message;

    if ([HTTPSTATUSCODE.Created, HTTPSTATUSCODE.Success].includes(status)) {
      // Success case - uncomment if needed
      // this.toastr.success(HTTPSTATUS.Success, message);
    } else if (status === HTTPSTATUSCODE.InternalServerError && message === HTTPSTATUS.Failure) {
      this.toastr.error(HTTPSTATUS.Failure, message);
    }
  }

  private handleErrorHeaders(error: HttpErrorResponse): void {
    const authorization = error.headers.get('authorization');
    const oldToken = sessionStorage.getItem('authorization');

    if (authorization && (!oldToken || oldToken !== authorization)) {
      sessionStorage.setItem('authorization', authorization);
    }
  }

  private handleErrorStatus(error: HttpErrorResponse): void {
    const errorMessage = error.error.detail || error.error.message || error.error.error;

    switch (error.status) {
      case HTTPSTATUSCODE.UnAuthorized:
        this.toastr.error(errorMessage);
        this.handleUnauthorized();
        break;
      case HTTPSTATUSCODE.UnSupported:
        this.toastr.error(HTTPSTATUS.Failure, errorMessage);
        break;
      case HTTPSTATUSCODE.BadRequest:
      case HTTPSTATUSCODE.InternalServerError:
      case HTTPSTATUSCODE.ExpectationFailed:
      case HTTPSTATUSCODE.UpgradeRequired:
        this.toastr.error(errorMessage);
      break;
      case HTTPSTATUSCODE.NotFound:
        this.toastr.error(errorMessage);
        break;
    }
  }

  private handleUnauthorized(): void {
    sessionStorage.clear();
    this.dialogRef.closeAll();
    this.authService.currentUserSub(null);
    this.authService.setSessionExpired(true);
    this.router.navigate(['/']);
  }
}
