import { ThemeService } from './../../core/services/theme.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DASHBOARDURL } from 'src/app/core/utils/enum/enum';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  form: FormGroup;
  userName: any;
  currentRoute: string = '';
  heading: any;
  industryOptions: any;
  LLMoptions : any ;
  selectedindustry: any;
  selectedLLM : any;
  isLoading: boolean = false;
  sideNavBar: boolean;
  sidenav: boolean;
  itemValue: number;
  isDropdownEnabled: boolean = false;
  isTeraTest: boolean = false;
  currentDashboardUrl: string;
  app_list : any ; 
  isAdmin : boolean = false;
  shouldShowBackButton: boolean = true;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uService: UserService,
    private authservice: AuthService,
    private apiservice: ApiService,
    private themeService: ThemeService,
    private encryptionService: EncryptService,
    private location: Location
  ) {
    this.form = new FormGroup({
      industry: new FormControl({ value: '', disabled: true }),
      LLM : new FormControl({ value: '', disabled: true })
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url;
        this.updateDropdownState(currentUrl);
        this.handleNavigation(currentUrl);
        this.shouldShowBackButton = currentUrl !== '/features/maindashboard';
      }
    });



    this.themeService.setTheme('inno');
    this.onGetIndustry();

  }



  ngOnInit() {
    const currentUser: any = window.sessionStorage.getItem('currentUser');
    const user = JSON.parse(currentUser);
    this.userName = user.user_name;
    this.isAdmin = user.user_profile === 'ADMIN';
    this.isAdmin ? this.form.get('LLM').enable() : this.form.get('LLM').disable();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path || '';
      });
    
    this.updateDropdownState(this.router.url);
  }

  goBack() {
    this.location.back();
  }

  
  updateDropdownState(url: string) {
    this.isDropdownEnabled = url === '/features/maindashboard' ;
    if (this.isDropdownEnabled) {
      this.form.get('industry').enable();
      this.form.get('LLM').enable();
    } else {
      this.form.get('industry').disable();
      this.form.get('LLM').disable();
    }
  }

  onGetIndustry() {
    this.isLoading = true;
    this.apiservice.apiRequestGet('entity_service/preferences').subscribe(
      data => { 
        const parsedData = data;
        this.industryOptions = parsedData?.industry_list || [];
        this.LLMoptions = parsedData?.llm_list || [];
        this.selectedindustry = parsedData?.default_industry.industry_id;
        this.selectedLLM = parsedData?.default_llm.llm_id; 
        this.form.patchValue({ industry: this.selectedindustry });
        this.form.patchValue({ LLM : this.selectedLLM });
        this.uService.setIndustry(this.selectedindustry);
        this.app_list = parsedData.application_list; 
        this.uService.setAppList(this.app_list);
        this.isLoading = false;
        this.updateDropdownState(this.router.url);
      },
      error => {
        console.error('Failed to fetch industries', error);
        this.isLoading = false;
      }
    );
  }

  onSelectIndustry(value: any) {
    this.selectedindustry = value.value;
    this.apiservice.apiRequestPost('entity_service/set_industry', `?industry_id=${this.selectedindustry}`, null).subscribe(
      (data: any) => {
        if (data) {
          this.form.patchValue({ industry: this.selectedindustry });
          this.onGetIndustry();
        }
      },
      err => {
        console.error('Error setting industry', err);
      }
    );
  } 


  onSelectLLM(value: any) {
    this.selectedLLM = value.value;
    this.apiservice.apiRequestPost('entity_service/set_llm',`?llm_id=${this.selectedLLM}`, null).subscribe(
      (data: any) => {
        if (data) {
          this.form.patchValue({ LLM: this.selectedLLM });
          this.onGetIndustry();
        }
      },
      err => {
        console.error('Error setting industry', err);
      }
    );
  }

  handleNavigation(url: string) {
    switch (true) {
      case url.includes('/features/gen-req'):
        this.heading = 'Generate Requirement';
        break;
      case url.includes('/features/rev-req'):
        this.heading = 'Review Requirements';
        break;
      case url.includes('/features/class-req'):
        this.heading = 'Classify Requirements';
        break;
      case url.includes('/features/group-req'):
        this.heading = 'Group Requirements';
        break;
      default:
        this.heading = '';
        break;
    }
  }

  logout() {
    this.authservice.logout().subscribe((data: any) => {
      if (data) {
        sessionStorage.clear();
        this.authservice.currentUserSub(null);
        this.router.navigate(['/']);
      }
    });
  }

  changeTheme(theme: any) {
    this.themeService.setTheme(theme);
    this.onClose();
  }

  onToggle(value: boolean, item?: any) {
    this.itemValue = item;
    this.sidenav = value;
    this.sideNavBar = value;
  }

  onNavigate(){
    this.router.navigate(['features/admin']);
  }

  onClose() {
    this.sidenav = false;
    this.sideNavBar = false;
  } 
}
