import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { mainApplicationList } from 'src/app/core/utils/data/shared-data';
import { IMainApplicationList } from 'src/app/core/utils/interface/shared.interface';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;
  mainAppList : IMainApplicationList[] | [];
  isLoading: boolean = false;
  isVmOff : boolean = false;
  secretKey = environment.routeSecretKey;
  user: any;
  refershtoken:any;
  authToken:any;

  constructor(
    private router: Router,
    private uService: UserService,
    private ecService : EncryptService
  ) { 
  }


  ngOnInit() {
    this.isLoading = true;

    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0;
    }); 
    this.user = JSON.parse(sessionStorage.getItem('currentUser'));
    
    if (this.user) {
      this.isVmOff = this.user.model_endpoint_active;      
    }

    this.refershtoken = sessionStorage.getItem('refershtoken');
    this.authToken = sessionStorage.getItem('authorization');
    this.mainAppList = mainApplicationList
  
    this.isLoading = false;
  }

  preloadFeatureImages(): void {
    const imageUrls = this.mainAppList.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }

  navigateToFeature(feature: IMainApplicationList): void {
    if (this.industryIsEmpty) {
      return;
    }

    if (feature.application_id === 1) {
      const user = {
        "email": this.user?.email,
        "user_name": this.user?.user_name,
        "user_id": this.user?.app_user_id,
        "auth_user_id": this.user?.auth_user_id || null,
        "auth": this.authToken || null,
        "refershtoken": this.refershtoken || null,
      };

      const url = window.location.href

      const userParams = `email=${encodeURIComponent(this.uService.encrypt(user.email, this.secretKey))}` +
        `&user_name=${encodeURIComponent(this.uService.encrypt(user.user_name, this.secretKey))}` +
        `&user_id=${encodeURIComponent(user.user_id)}` +
        `&auth_user_id=${encodeURIComponent(user.auth_user_id)}` +
        `&auth=${user.auth}` +
        `&refresh=${user.refershtoken}`+
        `&fromTeratics=${true}`+
        `&teraTicsURL=${url}`;

      const navigationUrl = `${feature.route}?${userParams}`;
      window.location.href = navigationUrl;
    } else {
      this.router.navigateByUrl(feature.route);
    }
  }

  
}
