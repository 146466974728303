import { GenerateUmlComponent } from './generate-uml/generate-uml.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GenerateRequirementsComponent } from './generate-requirements/generate-requirements.component';
import { ReviewRequirementsComponent } from './review-requirements/review-requirements.component';
import { InfoDashboardComponent } from './info-dashboard/info-dashboard.component';
import { ClassifyRequirementsComponent } from './classify-requirements/classify-requirements.component';
import { GroupRequirementsComponent } from './group-requirements/group-requirements.component';
import { GenerateVerificationComponent } from './generate-verification/generate-verification.component';
import { SummarizeQaComponent } from './summarize-qa/summarize-qa.component';
import { RequirementComparisonComponent } from './requirement-comparison/requirement-comparison.component';
import { HaraUsingHazopComponent } from './hara-using-hazop/hara-using-hazop.component';
import { FMEAComponent } from './fmea/fmea.component';
import { CybersecurityTaraComponent } from './cybersecurity-tara/cybersecurity-tara.component';
import { FunctionalTestcaseComponent } from './functional-testcase/functional-testcase.component';
import { RegAiDashboardComponent } from './reg-ai-dashboard/reg-ai-dashboard.component';
import { TeraTestAiDashboardComponent } from './tera-test-ai-dashboard/tera-test-ai-dashboard.component';
import { TeratestReviewRequirementComponent } from './teratest-review-requirement/teratest-review-requirement.component';
import { TeratestFuntionalTestcaseComponent } from './teratest-funtional-testcase/teratest-funtional-testcase.component';
import { TeratestGenerateTestcaseComponent } from './teratest-generate-testcase/teratest-generate-testcase.component';
import { FeatureDashboardComponent } from './feature-dashboard/feature-dashboard.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';

const routes: Routes = [
  { path: 'maindashboard', component: MainDashboardComponent },
  { path: 'dashboard', component: DashboardComponent, },
  { path: 'feature-dashboard', component: FeatureDashboardComponent },
  { path: 'reqai-dashboard', component: RegAiDashboardComponent },
  { path: 'teratestai-dashboard', component: TeraTestAiDashboardComponent },
  { path: 'gen-req', component: GenerateRequirementsComponent },
  { path: 'rev-req', component: ReviewRequirementsComponent },
  { path: 'info-dashboard', component: InfoDashboardComponent },
  { path: 'class-req', component: ClassifyRequirementsComponent },
  { path: 'group-req', component: GroupRequirementsComponent },
  { path: 'gen-ver', component: GenerateVerificationComponent },
  { path: 'summarize-qa', component: SummarizeQaComponent },
  { path: 'generate-uml', component: GenerateUmlComponent },
  { path: 'compare-req', component: RequirementComparisonComponent },
  { path: 'hazop', component: HaraUsingHazopComponent },
  { path: 'fmea', component: FMEAComponent },
  { path: 'cyber-tara', component: CybersecurityTaraComponent },
  { path: 'functional-test-case', component: FunctionalTestcaseComponent },
  { path: 'teratest-ai-rev-req', component: TeratestReviewRequirementComponent },
  { path: 'teratest-ai-functional-testcase', component: TeratestFuntionalTestcaseComponent },
  { path: 'teratest-ai-generate-testcase', component: TeratestGenerateTestcaseComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule { }
