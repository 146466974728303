import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { ApplicationList } from 'src/app/core/utils/data/shared-data';
import { IApplicationList } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;
  applicationList: IApplicationList[] | [];
  isLoading: boolean = false;
  isTbcmDomain: boolean = false;
  isVmOff : boolean = false;
  isVmOffStatus : boolean = false;

  
  constructor(
    private router: Router,
    private uService: UserService,
    private ecService : EncryptService
  ) { 
  }


  ngOnInit() {
    this.isLoading = true;

    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0;
    }); 


    const currentUser = sessionStorage.getItem('currentUser');
    if (currentUser) {
      const userData = JSON.parse(currentUser);
      this.isVmOff = userData.model_endpoint_active; 
    }

    this.uService.app_list$.subscribe(data => {
      if (data && Array.isArray(data)) {
        const processedApps = ApplicationList.map((x) => {
          const matchedApp = data.find(y => y.application_id === x.application_id);
          if (matchedApp) {
            matchedApp.encryptedAppId = this.ecService.encrypt(matchedApp.application_id.toString());
            return { ...x, ...matchedApp };
          }
          return x;
        });


        this.applicationList = [...processedApps]

        this.preloadFeatureImages();
        this.isLoading = false;
      }
    });
  }

  preloadFeatureImages(): void {
    const imageUrls = this.applicationList.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }

}
