import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Observable, debounceTime, first, fromEvent, merge } from 'rxjs';
import { ForgotPWD } from 'src/app/core/config/validation.config';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { GenericValidator } from 'src/app/core/utils/directive/generic-validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formControls!: ElementRef[];
  // Use with the generic validation message class
  private validationMessages: { [key: string]: { [key: string]: string } };
  displayMessage: { [key: string]: string } = {};
  private genericValidator: GenericValidator;
  otpButton: any = 'Send OTP';
  showOTP: any = false;
  passwordField: any = false;
  resetPwdFORM!: FormGroup;
  currentUser: any
  user_id: any;
  auth_key: any;
  successMsg:any=false;
  iloader:any;
  constructor(private _formBuilder: FormBuilder,
    private authService: AuthService) {
    this.validationMessages = ForgotPWD
    this.currentUser = window.sessionStorage.getItem('currentUser');
    this.genericValidator = new GenericValidator(this.validationMessages);
  }
  ngOnInit(): void {
    this.resetPwdFORM = this._formBuilder.group({
      sEmail: ['', Validators.required],
      sConsentCode: ['', Validators.required],
      sNewPwd: ['', Validators.required]
    });
  }
  submit() {
    if (this.resetPwdFORM.valid) {
      this.submitEM.emit(this.resetPwdFORM.value);
    }
  }
  @Input() error: string | null;

  @Output() submitEM = new EventEmitter();
  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formControls
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.resetPwdFORM.valueChanges, ...controlBlurs).pipe(
      debounceTime(600)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.resetPwdFORM);
    });
  }


  sendOTP() {
    this.authService.resetPassword(this.resetPwdFORM.controls['sEmail'].value).subscribe(data => {
      if (data.auth_user_id) {
        this.showOTP = true
        this.otpButton = 'OTP sent'
        this.user_id = data.auth_user_id
      }
    },
      error => {
        this.resetPwdFORM.controls['sEmail'].setErrors({ valid: true });
        this.displayMessage = this.genericValidator.processMessages(this.resetPwdFORM);
      }
    );
  }
  getColor() {
    return this.otpButton == 'Verified' ? 'verified' : this.otpButton == 'OTP sent' ? 'otp-sent' : this.otpButton == 'Send OTP' ? 'send-otp' : '';
  }
  verifyOTP() {
    this.authService.verifyOtp(this.user_id, this.resetPwdFORM.controls['sConsentCode'].value).pipe(first()).subscribe(data => {
      if (data) {
        this.auth_key = data.auth_key ? data.auth_key : '';
        this.otpButton = 'Verified'
        this.showOTP = false;
        this.passwordField = true
      }
    },
      error => {
        this.resetPwdFORM.controls['sConsentCode'].setErrors({ valid: true });
        this.displayMessage = this.genericValidator.processMessages(this.resetPwdFORM);
      }
    );
  }
    resetPassword() {
      this.resetPwdFORM.controls['sNewPwd'].disable()
    this.authService.resetPwd(this.user_id, this.resetPwdFORM.controls['sNewPwd'].value, this.auth_key).subscribe(data => {
      if (data) {
        this.successMsg = true
      }
    },
      error => {
        
      }
    );
  }
}