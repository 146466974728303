<div class="main">
    <div *ngIf="industryIsEmpty" class="row warning-message-row ">
      <div class="col-6 alert alert-warning" role="alert">
        <span class="pt-3 warning-message">An industry selection is required to move forward and use the feature.</span>
      </div>
    </div>
  
    <div *ngIf="!isVmOff" class="row warning-message-row ">
      <div class="col-6 alert alert-warning" role="alert">
        <span class="pt-3 warning-message">The LLM server is currently turned off. Please contact the IT team to turn on the LLM server to use the application.</span>
      </div>
    </div>
  
    <section class="dashboard-section">        
      <div class="container mx-auto">
        <div class="card" *ngFor="let feature of mainAppList" 
        (click)="navigateToFeature(feature)">
          <div class="card-body">
            <div class="icon-container">
              <img [lazyLoad]="feature.logo" alt="icons" height="50" style="object-fit: contain;">
              <span>{{feature.label}}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div> 
  
  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader"></div>
  </div>