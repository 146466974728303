import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environment/environment';
const BASE_URL = environment.url;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

 
  apiRequestGet(endPoint?: any, queryParam?: any): Observable<any> {
   const refreshToken: any = sessionStorage.getItem('refershtoken');
   const authorization: any = sessionStorage.getItem('authorization');
   const authUserid : any = sessionStorage.getItem('authuser');
 
    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.get<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID" : authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }

  apiRequestPost(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid : any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.post<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID" : authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  } 

  apiRequestPatch(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid : any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
  
    return this.http.patch<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID" : authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
  } 

  apiRequestPut(endPoint?: any, queryParam?: any, payload?: any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid : any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
  
    return this.http.put<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID" : authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err));
      })
    );
  }
  

  apiRequestDelete(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid : any = sessionStorage.getItem('authuser');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.delete<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        "X-Refresh-Token": refreshToken,
        "X-Access-Token": 'Bearer ' + authorization,
        "X-Auth-User-ID" : authUserid
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }

  convertTextToFormData(text: string): FormData {
    const formData = new FormData();
    formData.append('text', text);
    return formData;
  }

  downloadFile(endPoint: string, payload: any, filename: string) {
    const refreshToken: any = sessionStorage.getItem('refershtoken');
    const authorization: any = sessionStorage.getItem('authorization');
    const authUserid : any = sessionStorage.getItem('authuser');

    const url = BASE_URL + endPoint;
    const headers = new HttpHeaders({
      'ngrok-skip-browser-warning': 'truppe',
      "X-Refresh-Token": refreshToken,
      "X-Access-Token": 'Bearer ' + authorization,
      "X-Auth-User-ID" : authUserid
    });

    return this.http.post(url, payload, { headers: headers, responseType: 'blob' }).pipe(
        tap((blob: Blob) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    );
}

}
